import { Sidebar as ProSidebar, Menu, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import {
  CONVERTER_ROUTE,
  DRILLING_DYSFUNCTIONS_ROUTE,
  KEPPLER_ROUTE,
  // LOGS_ROUTE,
  MOE_ROUTE,
  OCR_ROUTE,
  PREDRILL_ROUTE,
  RASTERLOGS_ROUTE,
  MISSING_LOGS_ROUTE,
  WELL_LOGS_ROUTE
} from "../../constants";

const Sidebar = () => {
  // get current location
  return (
    <ProSidebar>
      <Menu>
        <a
          className="flex items-center py-8 px-6 w-full"
          href="https://thedrake.ai"
        >
          {/* Replace with your logo */}
          <div className="w-full">
            <img src="/drake.png" alt="Logo" className="mr-6 w-32" />
          </div>
          {/* <span className="font-bold text-xl">DRAKE AI for Oil & Gas</span> */}
        </a>
        {/* <SubMenu label="Charts">
        <MenuItem> Pie charts </MenuItem>
        <MenuItem> Line charts </MenuItem>
      </SubMenu> */}
        {/* <MenuItem to={PREDRILL_ROUTE}>Predrill</MenuItem> */}
        {/* <MenuItem to={MOE_ROUTE}> LLaMoe - Gen AI Bot </MenuItem>
        <MenuItem to={KEPPLER_ROUTE}>
          {" "}
          Keppler - Automated AI Platform{" "}
        </MenuItem> */}
        {/* <MenuItem to={OCR_ROUTE}> OCR - Oil and Gas Reports </MenuItem> */}
        {/* <SubMenu label="Logs"> */}
        {/* <MenuItem to={RASTERLOGS_ROUTE}> Raster Logs - AI Digitizer </MenuItem> */}
        <MenuItem to={MISSING_LOGS_ROUTE}> Missing Well Logs </MenuItem>
        {/* <MenuItem to={DRILLING_DYSFUNCTIONS_ROUTE}>
          {" "}
          Drilling Dysfunctions
        </MenuItem>
        <MenuItem to={CONVERTER_ROUTE}>
          TIFF to LAS conversion
        </MenuItem> */}

        {/* </SubMenu> */}
        <MenuItem to={WELL_LOGS_ROUTE}> Well Log Splicing </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
