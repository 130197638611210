export const ROOT_URL = "https://auth.kalpratech.com";
export const PREDRILL_SERVER = "https://auth.kalpratech.com";
export const PREDRILL_ROUTE = "/resource";
export const MOE_ROUTE = "/moe";
export const KEPPLER_ROUTE = "/keppler";
export const OCR_ROUTE = "/ocr";
export const RASTERLOGS_ROUTE = "/rasterlogs";
export const MISSING_LOGS_ROUTE = "/missing-logs";
export const WELL_LOGS_ROUTE = "/well-logs";
export const DRILLING_DYSFUNCTIONS_ROUTE = "/drilling-dysfunctions";
export const CONVERTER_ROUTE = "/converter"
export const KEPPLER_PORT = "8501";
export const OCR_PORT = "8502";
export const RASTERLOGS_PORT = "8503";
export const LOGS_PORT = "8504";
// export const PREDRILL_PORT = "8505";
export const DRILLING_DYSFUNCTIONS_PORT = "8505";
export const MOE_PORT = "5005";
export const CONVERTER_PORT = "8506";

export const MISSING_LOGS_URL = "https://missinglogs.kalpratech.com"
export const WELL_LOGS_URL = "https://welllogs.kalpratech.com"
