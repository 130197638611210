import React, { useContext } from "react";
import authCtx from "../store/auth/AuthContextProvider";

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = ({}) => {
  const { globalLogOutDispatch } = useContext(authCtx);
  return (
    <nav className="flex items-center justify-between p-4 max-w-7xl mx-auto h-[60px]">
      {/* <a className="flex items-center" href="https://thedrake.ai">
        <img src="/drake.png" alt="Logo" className="mr-6 w-28" />
      </a> */}
      <div></div>
      <div className="flex">
        {/* Add links or routing logic here */}
        {/* Logout button */}
        <button
          onClick={globalLogOutDispatch}
          className=" w-full flex justify-center bg-blue-500 text-gray-100 p-2  rounded-md tracking-wide
                        font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
