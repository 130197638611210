/* ui/src/App.tsx */

import { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Auth from "./components/auth/Auth";
import DrillingDysfunctions from "./components/drilling-dysfunctions";
import Keppler from "./components/keppler";
import Logs from "./components/logs";
import WellLogs from "./components/well-logs"
import Moe from "./components/moe/Moe";
import Ocr from "./components/ocr";
import RasterLogs from "./components/rasterlogs";
import Resource from "./components/resource/Resource";
import {
  CONVERTER_ROUTE,
  DRILLING_DYSFUNCTIONS_ROUTE,
  KEPPLER_ROUTE,
  // LOGS_ROUTE,
  MISSING_LOGS_ROUTE,
  WELL_LOGS_ROUTE,
  MOE_ROUTE,
  OCR_ROUTE,
  PREDRILL_ROUTE,
  RASTERLOGS_ROUTE,
} from "./constants";
import AuthContext from "./store/auth/AuthContextProvider";
import Converter from "./components/converter";

function App() {
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={authState.isLoggedIn ? `${MISSING_LOGS_ROUTE}` : "/user/login"} />
          }
        />

        <Route path="user">
          <Route path="register" element={<Auth />} />
          <Route path="login" element={<Auth />} />
        </Route>

        {authState.isLoggedIn && (
          <>
            <Route path={PREDRILL_ROUTE} element={<Moe />} />
            <Route path={MOE_ROUTE} element={<Moe />} />
            <Route path={KEPPLER_ROUTE} element={<Keppler />} />
            <Route path={OCR_ROUTE} element={<Ocr />} />
            <Route path={RASTERLOGS_ROUTE} element={<RasterLogs />} />
            <Route path={MISSING_LOGS_ROUTE} element={<Logs />} />
            <Route path={WELL_LOGS_ROUTE} element={<WellLogs/>} />
            <Route
              path={DRILLING_DYSFUNCTIONS_ROUTE}
              element={<DrillingDysfunctions />}
            />
            <Route 
              path={CONVERTER_ROUTE}
              element={<Converter />}
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
