import { useEffect, useRef, useState } from "react";
import PageLayout from "../PageLayout";
import { CONVERTER_PORT, ROOT_URL } from "../../constants";

const Converter = () => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleIframeLoad = () => {
      if (iframeRef.current) {
        // @ts-ignore
        iframeRef.current.contentWindow.postMessage("light-mode", "*");
        setLoading(false);
      }
    };

    if (iframeRef.current) {
      // @ts-ignore
      iframeRef.current.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        // @ts-ignore
        iframeRef.current.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [iframeRef.current]);

  return (
    <PageLayout>
      {loading && (
        <div className="w-full h-screen-minus-60 flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      <iframe
        ref={iframeRef}
        src={`${ROOT_URL}:${CONVERTER_PORT}`}
        // style={{ height: "450px", width: "100%;" }}
        className="w-full h-screen-minus-60"
      ></iframe>
    </PageLayout>
  );
};

export default Converter;
