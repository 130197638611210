import axios from "axios";
import { useEffect, useRef, useState } from "react";
import PageLayout from "../PageLayout";
import { MOE_PORT, ROOT_URL } from "../../constants";

const url = `${ROOT_URL}:${MOE_PORT}`;
let cancelled = false;

const Moe = () => {
  const [query, setQuery] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  // const [cancelled, setCancelled] = useState<boolean>(false);

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    // document.title = "Gen AI Moe";
    // let link = document.querySelector("link[rel~='icon']");
    // if (!link) {
    //   link = document.createElement("link");
    //   //@ts-ignore
    //   link.rel = "icon";
    //   document.head.appendChild(link);
    // }
    // //@ts-ignore
    // link.href = "/moe.png";
  }, []);

  return (
    <PageLayout>
      <div className="h-screen-minus-60 w-full flex justify-center overflow-hidden relative">
        <img
          className="absolute -top-[80px] opacity-10 -z-10"
          src="/moe.png"
          width={800}
          height={100}
          alt="bg"
        ></img>
        <div className="max-w-2xl p-8 pt-0 pb-2 w-full border-2 rounded-2xl m-8 flex flex-col overflow-auto">
          <div className="flex items-left flex-col w-full">
            <div className="w-full flex justify-center -translate-y-6">
              <div className="">
                <img className=" w-48" src="/moe.png" alt="moe logo"></img>
              </div>
            </div>
            <p className="text-base text-gray-800 text-center mb-2 -translate-y-12">
              Ask me anything about EnerGenie?
            </p>
            <label className="text-sm font-bold text-gray-500 tracking-wide text-left w-full">
              Enter your query
            </label>
            <div className="flex w-full">
              <form
                className="w-full flex"
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (query === "") {
                    setError("Please enter a query");
                    return;
                  }
                  setLoading(true);
                  // source = axios.CancelToken.source();
                  const response = await axios.get(`${url}/?query=${query}`, {
                    signal: signal,
                  });

                  // console.log(cancelled);

                  if (!cancelled) {
                    setAnswer(response.data);
                    setLoading(false);
                    setQuestion(query);
                    setQuery("");
                  }
                  cancelled = false;
                  // })
                  // .catch((error) => {
                  //   // console.log(error.message);
                  //   if (error.message !== "Operation canceled by the user.") {
                  //     alert("An error occurred. Please check your connection.");
                  //   }
                  //   setLoading(false);
                  //   // source = axios.CancelToken.source();
                  // });
                }}
              >
                <input
                  className="text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500 w-full bg-transparent"
                  type="text"
                  placeholder="Enter your query"
                  value={query}
                  onChange={(e) => {
                    setError("");
                    setQuery(e.target.value);
                  }}
                />

                {loading ? (
                  <button
                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded flex"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      cancelled = true;
                      setLoading(false);
                      controller.abort();
                    }}
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8z"
                      ></path>
                    </svg>
                    <p>Cancel</p>
                  </button>
                ) : (
                  <button
                    className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded flex"
                    type="submit"
                  >
                    <p>Query</p>
                  </button>
                )}
              </form>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {question && answer && (
              <div className="w-full rounded-md mt-10">
                <h2 className="p-2 bg-indigo-500/20 text-indigo-500 font-bold">
                  Question
                </h2>
                <p className="p-2">{question}</p>
              </div>
            )}

            {answer && (
              <div className="w-full rounded-md mt-5">
                <h2 className="p-2 bg-indigo-500/20 text-indigo-500 font-bold">
                  Answer
                </h2>
                <p className="p-2">{answer}</p>
              </div>
            )}
          </div>{" "}
          <div className="flex-grow"></div>
          <div className=" ">
            <p className="text-gray-500 text-sm w-full text-center">
              © 2024 Kalpratech. All rights reserved. This material is the
              proprietary property of Kalpratech.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default Moe;
