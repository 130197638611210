import { MenuItem as ProMenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

interface MenuItemProps {
  to: string;
  children: React.ReactNode;
}

const MenuItem = ({ children, to }: MenuItemProps) => {
  const location = useLocation();
  return (
    <Link to={to}>
      <ProMenuItem
        className={`cursor-pointer transition ease-in duration-300 ${
          location.pathname === to ? "bg-blue-500 text-white shadow-lg" : " "
        }`}
        data-selected={location.pathname === to}
      >
        {children}
      </ProMenuItem>
    </Link>
  );
};

export default MenuItem;
