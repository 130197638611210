import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <div className="flex h-screen">
      <div className="h-screen">
        <Sidebar />
      </div>
      <div className="w-full overflow-auto">
        <Navbar />
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
